/* Styling for the footer */
.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    text-align: center;
    margin-top: auto; /* Push the footer to the bottom */
}

/* Footer content wrapper */
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

/* Footer logo section */
.footer-logo {
    flex: 1;
    text-align: left;
    padding: 10px 20px;
}

.footer-logo h2 {
    font-size: 2rem;
    color: #fff;
}

.footer-logo p {
    font-size: 1rem;
    color: #ccc;
}

/* Footer links section */
.footer-links {
    flex: 1;
    padding: 10px 20px;
}

.footer-links h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.footer-links ul li a:hover {
    color: #fff;
}

/* Footer social media section */
.footer-social {
    flex: 1;
    padding: 10px 20px;
}

.footer-social h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.footer-social .social-icons {
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
}

.footer-social .social-icons a {
    color: #ccc;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.footer-social .social-icons a:hover {
    color: #fff;
}

/* Footer bottom section */
.footer-bottom {
    background-color: #222;
    padding: 10px;
    margin-top: 30px;
}

.footer-bottom p {
    font-size: 1rem;
    color: #aaa;
}
/* Responsive design for mobile */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center; /* Center all content horizontally */
        text-align: center;  /* Center text */
    }

    .footer-logo {
        text-align: center; /* Center the logo section */
        margin-bottom: 20px;
    }

    .footer-links, .footer-social {
        flex: 100%;
        text-align: center; /* Center the links and social media */
        margin-bottom: 20px; /* Add spacing between sections */
    }

    .footer-links ul {
        display: block;
        margin-top: 10px;
        padding: 0;
        text-align: center; /* Center the list items */
    }

    .footer-links ul li {
        margin-bottom: 10px;
    }

    .footer-social .social-icons {
        display: flex;
        justify-content: center; /* Center the social icons */
        gap: 15px;
    }

    .footer-social .social-icons a {
        font-size: 1.5rem; /* Adjust icon size */
    }

    .footer-bottom p {
        font-size: 1rem; /* Adjust footer text size */
        color: #aaa;
        margin-top: 20px; /* Add space above the footer text */
    }
}
