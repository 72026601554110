/* General container styling for the category page */
.category-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Heading styling */
  .category-page h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: bold;
  }
  
  /* Container for all products */
  .category-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Individual product card styling */
  .product-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Hover effect for product cards */
  .product-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  /* Product image styling */
  .product-card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  /* Product name styling */
  .product-card h3 {
    font-size: 1.5rem;
    color: #444;
    margin: 10px 0;
    font-weight: 600;
  }
  
  /* Product price styling */
  .product-card p {
    font-size: 1.2rem;
    color: #666;
    font-weight: bold;
  }
  
  /* Styling for empty state message */
  .category-page p {
    font-size: 1.2rem;
    color: #888;
    margin-top: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .category-page h2 {
      font-size: 2rem;
    }
  
    .product-card h3 {
      font-size: 1.3rem;
    }
  
    .product-card p {
      font-size: 1rem;
    }
  }
  