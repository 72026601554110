/* General Container */
.product-details-container {
  font-family: 'Arial', sans-serif;
  margin-left: 80px;
  max-width: 1200px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 80px; /* Ensure there's some space on the right for larger screens */
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  color: #999;
  margin-bottom: 40px;
}
.breadcrumb a {
  color: #00bfa6;
  text-decoration: none;
  margin-right: 5px;
}
.breadcrumb a:hover {
  text-decoration: underline;
}

/* Product Details Section */
.product-details {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  flex-wrap: wrap; /* Added for better wrapping on mobile */
}

/* Thumbnails Column */
.thumbnails-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0 0 80px;
  width: 20%;
}

.thumbnails-column img {
  width: 90px;
  height: 90px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border 0.2s ease;
  background-color: #f4f8fb;
}

.thumbnails-column img:hover {
  border-color: #00bfa6;
}

/* Main Image Section */
.main-image-container {
  flex: 1;
  width: 50%;
  margin-top: -1px;
}

.main-image {
  height: 300px;
  width: 280px;
  border-radius: 30px;
  padding: 40px;
  background-color: #f4f8fb;
  object-fit: contain; /* Ensure image doesn't stretch on smaller screens */
}

/* Product Info Section */
.product-info {
  flex: 2;
  width: 30%;
}

.product-category {
  font-size: 14px;
  color: #ff9800;
  text-transform: uppercase;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.price {
  font-size: 20px;
  margin: 15px 0;
}

.current-price {
  color: #00bfa6;
  font-weight: bold;
  margin-right: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #bbb;
  font-size: 16px;
}

.quantity-section {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

#quantity {
  width: 60px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-to-cart {
  display: inline-block;
  padding: 10px 20px;
  background-color: #00bfa6;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}
.add-to-cart:hover {
  background-color: #008f82;
}

/* Features Section */
.product-features {
  margin-top: 40px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.product-features h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.product-features ul {
  list-style: none;
  padding: 0;
}

.product-features li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Adjust product details layout to column */
  .product-details {
    flex-direction: column; /* Stack all elements vertically */
    align-items: center; /* Center align elements */
    gap: 20px; /* Reduced gap for mobile */
  }

  /* Thumbnails Column: Adjust thumbnail images */
  .thumbnails-column {
    display: none;
    width: 50%; /* Increase thumbnail width on mobile */
    margin: 0 auto; /* Center thumbnails */
  }

  .thumbnails-column img {
    width: 60px;
    height: 60px;
    padding: 8px;
  }

  /* Main Image: Make it full-width on mobile */
  .main-image-container {
    /* width: 100%; Full width for the main image */
    padding: 10px;
  }

  .main-image {
    height: auto; /* Let the image scale automatically */
    width: 100%; /* Full width on mobile */
    padding: 20px; /* Reduce padding */
  }

  /* Product Info Section: Adjust the layout */
  .product-info {
    width: 100%; /* Full width on mobile */
    text-align: center; /* Center the text */
  }

  h1 {
    font-size: 22px; /* Slightly smaller font for mobile */
  }

  .price {
    font-size: 18px;
  }

  .current-price {
    font-size: 18px; /* Make price a bit smaller */
  }

  .original-price {
    font-size: 14px; /* Smaller font for original price */
  }

  /* Quantity Section: Adjust layout for mobile */
  .quantity-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  #quantity {
    width: 50px;
  }

  .add-to-cart {
    width: 100%; /* Full width for the button */
    padding: 15px;
  }

  /* Product Features: Adjust heading and font size */
  .product-features h2 {
    font-size: 18px; /* Smaller font for headings */
  }

  .product-features li {
    font-size: 14px; /* Smaller font for features */
  }

  /* Reduce the margin/padding on the main container */
  .product-details-container {
    margin-left: 20px; /* Add space on the sides */
    margin-right: 20px;
    padding: 15px; /* Less padding on smaller screens */
  }
}
