.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    max-width: 250px;
    margin: 20px;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-card img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .product-card h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .product-card p {
    font-size: 16px;
    color: #1e90ff;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .product-card button {
    padding: 10px 20px;
    background-color: #1e90ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .product-card button:hover {
    background-color: #0d6efd;
  }
  