/* 404 Page */
.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Make sure the content takes the full viewport height */
  text-align: center;
  padding: 40px;
  background-color: #f8f8f8;
}

.not-found-page h2 {
  font-size: 2.5rem;
  color: #ff4081;
  margin-bottom: 20px;
}

.not-found-page p {
  font-size: 1.2rem;
  color: #555;
}

.not-found-page .gift-image {
  max-width: 200px;
  margin: 20px 0;
}

.not-found-page .back-home {
  font-size: 1.2rem;
  color: #fff;
  background-color: #ff4081;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.not-found-page .back-home:hover {
  background-color: #f50057;
}
