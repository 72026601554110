.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 70px;
  margin-right: 70px;
  padding: 10px 20px;
  background-color: #ffffff;
  /* border-bottom: 1px solid #e0e0e0; */
}

.header h1 {
  font-size: 24px;
  color: #f21f42;
  font-weight: bold;
}

.header nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
}

.header nav ul li {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.header nav ul li:hover {
  color: #ef1078;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .header {
      margin-left: 20px;
      margin-right: 20px;
  }

  .header h1 {
      font-size: 20px;  /* Smaller font size on mobile */
  }


  .header nav ul li {
      font-size: 15px;  /* Smaller font size for mobile */
  }
}

@media (max-width: 480px) {
  .header {
      padding: 8px 15px;  /* Less padding on small screens */
  }

  .header h1 {
      font-size: 18px;  /* Even smaller font size on very small screens */
  }

  .header nav ul li {
      font-size: 13px;  /* Further reduced font size for very small screens */
  }
}
