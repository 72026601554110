/* Hero Section */
.hero-section {
  margin-left: 80px;
  margin-right: 80px;
  border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #f4f8fb;
    border-radius: 15px;
    margin-bottom: 30px;
  }
  

  .hero-text {
    margin-left: 50px;
    max-width: 50%;
  }

  .hero-text i{
    font-size: 12px;
  }
  
  .hero-text h1 {
    font-size: 28px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .hero-text p {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .hero-text button {
    padding: 12px 30px;
    /* background-color: #f21f42; */
    background-color: #ef1078;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .hero-text button:hover {
    /* background-color: #ef1078; */
    background-color: #f21f42;

  }
  
  .hero-image {
    max-width: 30%;
    margin-right: 80px;

    text-align: center;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  

  /* Mobile-specific adjustments */
@media only screen and (max-width: 768px) {
  .hero-section {
    margin-left: 20px; /* Reduce margins for mobile */
    margin-right: 20px; 
    padding: 20px; /* Reduce padding */
    flex-direction: column; /* Stack elements vertically */
  }

  .hero-text {
    margin-left: 0; /* Remove left margin */
    max-width: 100%; /* Allow full width for text */
    text-align: center; /* Center-align text */
  }

  .hero-text h1 {
    font-size: 24px; /* Adjust font size */
  }

  .hero-text p {
        text-align: center; /* Center-align text */

    font-size: 16px; /* Adjust font size */
  }

  .hero-text button {
    padding: 10px 25px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
  }

  .hero-image {
    max-width: 100%; /* Ensure image takes up full width */
    margin-top: 20px; /* Add spacing between text and image */
    align-items: center;
    text-align: center; /* Center-align image */
    margin-left: 20px;
  }

  .hero-image img {
    width: 100%; /* Ensure image is responsive */
    height: auto; /* Maintain aspect ratio */
  }
}
