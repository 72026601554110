/* Styling for the main section */
.product {
    margin-left: 90px;
    margin-bottom: -70px;
}

.product-header{
    margin-left: 80px;
}
.product-header i {
    
  color: #1e90ff;
  font-weight: 700;
  font-size: 12px;
  line-height: -1;
}

.product-header h2 {
    font-size: 28px;
    font-weight: bold;
    color: #5a5858;
    margin-top: 10px;
}

/* Container for product list */
.product-list {
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; Allow items to wrap on smaller screens */
    gap: 20px;
    padding: 20px;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 1200px; /* Limit width for large screens */
}
.product-box{
    background-color: #f4f8fb;
    padding: 15px;
    width: auto;
    padding: 34px;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    align-items: center;
    align-content: center;
}

/* Individual product styling */
.product-item {
    /* text-align: center; */
    padding: 15px;
    border-radius: 20px;
    position: relative;
    height: 370px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adjust values for desired effect */

    transition: transform 0.3s, background-color 0.3s;

    flex: 1 1 calc(25% - 20px); /* Responsive column width */
    max-width: 250px; /* Ensure a fixed max width */
    min-width: 200px; /* Prevent items from shrinking too small */
    margin: 10px;
}

.product-item:hover {
    transform: translateY(-10px); /* Subtle lift effect */
    background-color: #ef1078; 
}

/* Badge styling */
/* .product-item .product-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
} */

/* Product text styling */
.product-item p {
    font-size: 14px;
    color: #555151;
    margin: 5px 0;
}

.product-item .product-category {
    font-size: 12px;
    font-weight: bold;
    color: #f0a500;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.product-item .product-name {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

.product-header hr{
    background-color: #f0a500;
    height: 4px;
    border-radius: 2px;
    margin-right: 80px;
    border: none;
}

.product-item .product-price {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}


.product-item .product-price .orginal-price {
    font-weight: 300;
    text-decoration: line-through;
    padding: 8px;
    /* margin-right: 8px; */
}
.product-item .product-price .category-tag {
    /* text-decoration: line-through; */
    color: white;
    background-color: #ef1078;
    width: 100px;
    padding: 8px;
    font-size: 10px;
    border-radius: 10px;
    margin-right: 8px;
}

/* Image styling inside product items */
.product-item img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
    /* transition: transform 0.3s; */
}



/* Button styling */
.product-item .view-button {
    display: inline-block;
    background-color: #ef1078;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.product-item .view-button:hover {
    background-color: #f21f42;
}

/* Responsive design */
@media (max-width: 768px) {
    .product-list {
        flex-direction: column;
        margin: 20px;
    }
    .product-header hr{
        background-color: #f0a500;
        height: 4px;
        border-radius: 2px;
        margin-right: 40px;
        border: none;
    }
    .product-box{
        background-color: #f4f8fb;
        padding: 15px;
        width: auto;
        padding: 34px;
        border-radius: 30px;
        cursor: pointer;
        transition: transform 0.3s, background-color 0.3s;
        align-items: center;
        align-content: cent
    
    
    }

    .product-item {
        max-width: 100%; /* Full width for product items */
    }

    .product-item img {
        height: 250px;
        width: 100%;
    }

    .product-header{
        margin-left: 40px;
    }

    .product h2 {
        font-size: 24px;
    }

    .product i {
        font-size: 10px;
    }
}
