/* Styling for Category Section */
.category {
  margin-left: 90px;
  margin-bottom: -40px;
}

.category hr{
  background-color: #ef1078;
  height: 4px;
  margin-right: 80px;
  border: none;
}

.category i {
  color: #1e90ff;
  font-weight: 700;
  font-size: 12px;
  /* line-height: -1; */
}

.category h2 {
  font-size: 28px;
  font-weight: bold;
  color: #5a5858;
}

/* Container for Category List */
.category-list {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 80px;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

/* Individual Category Item */
.category-item {
  text-align: center;
  padding: 15px;
  background-color: #e8e8e8;
  border-radius: 20px;
  cursor: pointer;
  flex: 1; /* Distribute the items evenly */
  min-width: 100px; /* Set a minimum width for each item */
  height: 100px;
  transition: transform 0.3s, background-color 0.3s;
  margin: 10px; /* Add margin for spacing between items */
}

.category-item:hover {
  transform: translateY(-15px);
  background-color: #ef1078;
}

.category-item:hover p {
  font-size: 14px;
  color: #fff;
}

.category-item p {
  font-size: 14px;
  color: #555;
  transition: font-size 0.3s, color 0.3s;
}

.category-item span {
  display: block;
  font-size: 24px;
  margin-bottom: 5px;
  color: #1e90ff;
}

/* Responsive design for mobile (max-width: 768px) */
@media (max-width: 768px) {

  .category {
    margin-left: 40px;  /* Adjust margin for mobile */
  }

  .category h2 {
    font-size: 24px; /* Smaller heading font for mobile */
    /* text-align: center; Center align the category header */
    margin-bottom: 20px; /* Space between heading and items */
  }

  
  .category hr{
    background-color: #ef1078;
    height: 4px;
    margin-right: 40px;
    border: none;
  }

  .category-list {
    flex-direction: row;  /* Stack category items vertically on smaller screens */
    gap: 10px;  /* Reduce gap between items */
    margin: 20px;  /* Reduce the margin for mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .category-item {
    max-width: 100%; /* Full width for each item on mobile */
    margin-bottom: 20px; /* Space between category items */
    height: 80px;  /* Adjust height to fit better */
  }

  .category-item p {
    font-size: 12px; /* Smaller text for category items */
  }

  .category-item span {
    font-size: 20px; /* Smaller icon size */
  }

  /* Adjust hover effects for mobile */
  .category-item:hover {
    transform: translateY(-5px); /* Less pronounced hover effect on mobile */
  }

  .category-item:hover p {
    font-size: 13px; /* Adjust text size on hover for mobile */
  }
}
